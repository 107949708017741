import {
  faChevronUp,
  faDownload,
  faFileLines,
  faFilePdf,
  faFilePen,
  faFileSignature,
  faInfinity,
  faLock,
  faLockOpen,
  faMobile,
  faSpinner,
  faTv,
  faUnlockKeyhole,
  faUserGraduate,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useEffect, useReducer, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Popconfirm } from "antd";
import SingleLectureCountDown from "./components/SingleLectureCountDown";
import VdoCiperrPlayer from "./vdoCipherComponents/VdoCiperrPlayer";
import useVdocipher from "./vdoCipherComponents/hooks/useVdocipher";
import { BunnyVideo } from "./components/BunnyVideo";

function StudentLecture() {
  const { lectureID } = useParams();
  const [videoRef, setVideoRef] = useState(null);
  const videoContainerRef = useRef();
  const { loadVideo, isAPIReady } = useVdocipher();

  function reducer(state, action) {
    switch (action.type) {
      case "setCollapse": {
        return {
          ...state,
          collapse: action.payload,
        };
      }
      case "setSrc": {
        return {
          ...state,
          src: action.payload,
        };
      }
      case "setCurrentVideo": {
        return {
          ...state,
          currentVideo: action.payload,
        };
      }
      case "setPlatform": {
        return {
          ...state,
          platform: action.payload,
        };
      }
      case "setBuyLecture": {
        return {
          ...state,
          buyLecture: action.payload,
        };
      }
      case "setBuyLectureByPoints": {
        return {
          ...state,
          buyLectureByPoints: action.payload,
        };
      }
      case "setBuyLectureByAmount": {
        return {
          ...state,
          buyLectureByAmount: action.payload,
        };
      }
      case "setRefetch": {
        return {
          ...state,
          refetch: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    collapse: false,
    src: "",
    refetch: false,
    currentVideo: null,
    buyLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByPoints: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByAmount: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //!---- get lecture info -------
  const [LectureInfoSuccess, LectureInfoErrors, LectureInfoLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_LECTURE_INFO_API}${lectureID}`,
    "GET",
    lectureID,
    state.refetch
  );

  console.log(LectureInfoSuccess);

  //!---- buy lecture  -------
  const [buyLectureSuccess, buyLectureErrors, submitLoading] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_LECTURE_API,
    "POST",
    state.buyLecture.flag,
    state.buyLecture.dependency,
    state.buyLecture.data,
    true
  );
  //!---- buy lecture By Points -------
  const [
    buyLectureByPointsSuccess,
    buyLectureByPointsErrors,
    buyLectureByPointsLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_BY_POINTS_API,
    "POST",
    state.buyLectureByPoints.flag,
    state.buyLectureByPoints.dependency,
    state.buyLectureByPoints.data,
    true
  );
  // !---- buy lecture By amount -------
  const [
    buyLectureByAmountSuccess,
    buyLectureByAmountErrors,
    buyLectureByAmountLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_BY_AMOUNT_API,
    "POST",
    state.buyLectureByAmount.flag,
    state.buyLectureByAmount.dependency,
    state.buyLectureByAmount.data,
    true
  );

  //!---refetch the lecture data again if the code is correct to open the paid content

  useEffect(() => {
    if (
      buyLectureSuccess ||
      buyLectureByPointsSuccess ||
      buyLectureByAmountSuccess
    ) {
      dispatch({
        type: "setRefetch",
        payload: !state.refetch,
      });
      reset();
    }
  }, [buyLectureByPointsSuccess, buyLectureSuccess, buyLectureByAmountSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
  });

  const handleBuyLecture = (data) => {
    dispatch({
      type: "setBuyLecture",
      payload: {
        flag: "buyLecture",
        dependency: !state.buyLecture.dependency,
        data: { ...data, id: lectureID },
      },
    });
  };
  const handleBuyLectureByPoints = () => {
    dispatch({
      type: "setBuyLectureByPoints",
      payload: {
        flag: "buyLectureByPoints",
        dependency: !state.buyLectureByPoints.dependency,
        data: { id: lectureID },
      },
    });
  };
  const handleBuyLectureByAmount = () => {
    dispatch({
      type: "setBuyLectureByAmount",
      payload: {
        flag: "buyLectureByAmount",
        dependency: !state.buyLectureByAmount.dependency,
        data: { id: lectureID },
      },
    });
  };
  if (LectureInfoLoading) {
    return <Loader />;
  }
  if (LectureInfoErrors && typeof LectureInfoErrors === "string") {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{LectureInfoErrors}</p>
      </div>
    );
  }

  return (
    <section
      className={`width min-h-screen   py-20 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Lectures | Mr Ahmed Rashad"></HelmetTags>
      {/**
       * //!---- lecture-content and buying form -----
       */}
      <div className="lecture-top flex w-full justify-between gap-20 flex-col items-center ">
        {/**
         * //!------content-----
         */}
        <div
          dir="ltr"
          className={` flex flex-col w-full items-center text-center transition-{width} duration-500  ease-in-out md:w-full`}
        >
          <div
            className={`lecture-session-img-container  relative w-3/4 overflow-hidden rounded-[25px] border-2 border-secondary p-7  lg:w-full  h-[320px] max-h-[320px]  `}
          >
            <img
              className="h-full w-full cursor-pointer rounded-[20px] object-cover duration-300 hover:saturate-150"
              src={LectureInfoSuccess?.data?.session_info?.img}
              alt={LectureInfoSuccess?.data?.session_info?.title}
            />
            <div className="stage-wrapper absolute right-14 top-14 flex h-12 w-fit items-center justify-center gap-3 rounded-xl bg-logoYellow text-black px-3 text-2xl  font-semibold">
              <FontAwesomeIcon className="h-6 w-6 " icon={faUserGraduate} />
              <p className="stage pointer-events-none text-2xl font-semibold">
                {LectureInfoSuccess?.data?.session_info?.stage === 3
                  ? "Third grade"
                  : LectureInfoSuccess?.data?.session_info?.stage === 2
                    ? "Second grade"
                    : LectureInfoSuccess?.data?.session_info?.stage === 1
                      ? "First secondary"
                      : LectureInfoSuccess?.data?.session_info?.stage === 22
                        ? "Second preparatory"
                        : LectureInfoSuccess?.data?.session_info?.stage === 11
                          ? "First preparatory"
                          : LectureInfoSuccess?.data?.session_info?.stage === 33
                            ? "Third preparatory"
                            : ""}
              </p>
            </div>
          </div>
          <div
            className={`title-description mt-8 rounded-2xl border-2 border-secondary  p-5 w-3/4 text-center`}
          >
            <h1 className="lecture-session-title mb-9 text-4xl  font-bold">
              {LectureInfoSuccess?.data?.session_info?.title}
            </h1>
            <h2 className="lecture-session-description text-3xl">
              {LectureInfoSuccess?.data?.session_info?.description}
            </h2>
          </div>
          {LectureInfoSuccess?.data?.session_info?.is_buy === 1 && (
            <SingleLectureCountDown
              expireDate={LectureInfoSuccess?.data?.session_history.expire_at}
              dispatch={dispatch}
              state={state}
            />
          )}
        </div>
        {/**
         * //!------buying form-----
         */}
        {LectureInfoSuccess?.data?.session_info?.is_buy === 0 && (
          <div dir="ltr" className={`  flex  w-full justify-center`}>
            <div className="lecture-session-details-wrapper   flex h-auto w-full items-start overflow-hidden rounded-2xl border-2 border-secondary p-8  shadow-xl justify-between gap-14 md:flex-col-reverse md:items-center">
              <div className="lock-info relative w-1/2 md:w-full  rounded-xl p-5 flex justify-center">
                <div className="w-[300px] relative py-3 ">
                  <FontAwesomeIcon
                    className=" h-full w-full text-9xl opacity-10 absolute right-0 "
                    icon={faLock}
                  />
                  <h2 className="mb-10 mt-3 text-start text-3xl font-bold  ">
                    This lecture contains:
                  </h2>
                  <ul className="flex flex-col items-start justify-start  gap-8">
                    <li className="flex items-center justify-start gap-4 ">
                      <FontAwesomeIcon icon={faTv} />
                      <p className=" ">Videos.</p>
                    </li>
                    <li className="flex items-center justify-start gap-4">
                      <FontAwesomeIcon icon={faFileLines} /> <p>Exams.</p>
                    </li>

                    <li className="flex items-center justify-start gap-4">
                      <FontAwesomeIcon icon={faMobile} />{" "}
                      <p>Available via mobile and computer.</p>
                    </li>
                  </ul>
                </div>
              </div>

              {LectureInfoSuccess?.data?.session_info?.price !== 0 ? (
                <div className="form w-1/2 md:w-full  inset-0 flex h-full  items-center justify-between gap-6 p-5">
                  <form
                    method="post"
                    onSubmit={handleSubmit(handleBuyLecture)}
                    className="flex  w-[300px] flex-col items-center gap-8 "
                  >
                    <h3 className="price  my-5 text-center text-3xl font-bold">
                      <span>
                        {" "}
                        {LectureInfoSuccess?.data?.session_info?.price}
                      </span>{" "}
                      <span>EGP</span>
                    </h3>
                    <input
                      className="code-input h-16 w-1/2 rounded-lg pl-7"
                      placeholder="Add your code"
                      type="text"
                      name="code"
                      {...register("code", {
                        required: true,
                        maxLength: 255,
                        minLength: 1,
                        pattern: /^[A-Za-z0-9-_]*$/,
                      })}
                    />
                    {errors.code && (
                      <p className="w-full pb-4 text-center text-[12px] text-blue-900 ">
                        {errors.code.type === "required" &&
                          "Please fill out this field"}
                        {errors.code.type === "maxLength" &&
                          "The maximum number of characters is 255 characters"}
                        {errors.code.type === "minLength" &&
                          "Please write at least one letter"}
                        {errors.code.type === "pattern" &&
                          "Only English letters and numbers are allowed"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      buyLectureErrors &&
                        buyLectureErrors?.response?.data?.errors?.code && (
                          <p className="w-full  text-[12px] text-blue-900  ">
                            {buyLectureErrors?.response?.data?.errors?.code[0]}
                          </p>
                        )
                    }
                    {/** submit */}

                    <button
                      disabled={
                        !isValid || (submitLoading && state.buyLecture.flag)
                      }
                      className=" submit mt-6 w-full "
                      type="submit"
                    >
                      {submitLoading && state.buyLecture.flag ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Buy the lecture"
                      )}
                    </button>
                  </form>

                  <div className="flex flex-col w-[300px]  items-ceter justify-between h-full gap-8 border-l-2 border-secondary pl-6">
                    <Popconfirm
                      okText="Confirm"
                      okButtonProps={{
                        style: {
                          display: `${
                            LectureInfoSuccess?.data?.session_info?.amount >=
                            LectureInfoSuccess?.data?.session_info.price
                              ? "inline-block"
                              : "none"
                          }`,
                        },
                      }}
                      okType="Confirm"
                      cancelText="Cancel"
                      color="#fecaca"
                      onConfirm={handleBuyLectureByAmount}
                      title={`${
                        LectureInfoSuccess?.data?.session_info?.amount >=
                        LectureInfoSuccess?.data?.session_info.price
                          ? `After purchase , your balance is ${
                              LectureInfoSuccess?.data?.session_info?.amount -
                              LectureInfoSuccess?.data?.session_info?.price
                            } `
                          : "Your Balance Not Enough!"
                      }`}
                    >
                      <button
                        disabled={
                          submitLoading && state.buyLectureByAmount.flag
                        }
                        className=" submit w-full "
                        type="submit"
                      >
                        {buyLectureByAmountLoading &&
                        state.buyLectureByAmount.flag ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          `Buy by your balance `
                        )}
                      </button>
                    </Popconfirm>
                    <div className="font-bold w-full text-center">Or</div>

                    <Popconfirm
                      okText="Confirm"
                      okType="danger"
                      okButtonProps={{
                        style: {
                          display: `${
                            LectureInfoSuccess?.data?.session_info?.points >=
                            LectureInfoSuccess?.data?.session_info.lec_points
                              ? "inline-block"
                              : "none"
                          }`,
                        },
                      }}
                      cancelText="Cancel"
                      color="#fecaca"
                      //okButtonProps={{ loading: loading }}
                      onConfirm={handleBuyLectureByPoints}
                      title={`${
                        LectureInfoSuccess?.data?.session_info?.points >=
                        LectureInfoSuccess?.data?.session_info.lec_points
                          ? `After purchase , your Points is ${
                              LectureInfoSuccess?.data?.session_info?.points -
                              LectureInfoSuccess?.data?.session_info?.lec_points
                            }`
                          : "Your Points Not Enough!"
                      }`}
                    >
                      <button
                        disabled={submitLoading && state.buyLecture.flag}
                        className=" submit mt-6 w-full "
                        type="submit"
                      >
                        {submitLoading && state.buyLecture.flag ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          `Buy by your points`
                        )}
                      </button>
                    </Popconfirm>
                  </div>
                </div>
              ) : (
                <p className="w-1/2 md:w-full  inset-0 flex h-full items-center justify-center font-bold text-3xl">
                  Free Lecture
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {/**
       * //!---- lecture homework  quiz & PDFs -----
       */}
      {LectureInfoSuccess?.data?.session_info?.is_buy === 1 &&
        (LectureInfoSuccess?.data?.session_history?.is_hw === 1 ||
          LectureInfoSuccess?.data?.session_history?.is_quiz === 1) && (
          <section className="mt-24 flex w-full items-start justify-center gap-4 rounded-2xl border-2 border-secondary py-5 dark:bg-dark dark:text-light md:flex-col-reverse md:items-center md:justify-start">
            {/**
             * //!---- homework -----
             */}
            {LectureInfoSuccess?.data?.session_history?.is_hw === 1 && (
              <div className="homework h-96 w-1/3 border-r-2 border-r-secondary p-8 py-4 md:w-full md:border-r-0 md:border-t-2 md:border-t-secondary md:pt-10">
                <div className="mb-12 flex justify-between">
                  {LectureInfoSuccess?.data?.session_history?.is_hw_result ===
                  1 ? (
                    <Link
                      to={`/home/lectures/sessions/modalAnswer/`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      Answer model
                    </Link>
                  ) : (
                    <Link
                      to={`/home/homework/${lectureID}`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      Start the homework
                    </Link>
                  )}

                  <h2 className=" w-fit  text-3xl font-semibold	 underline decoration-wavy underline-offset-8 ">
                    <span className="mr-4">Homework</span>
                    <FontAwesomeIcon icon={faFilePen} />
                  </h2>
                </div>

                {LectureInfoSuccess?.data?.session_history?.is_hw_result ===
                  1 && (
                  <div className="student-quiz-info flex w-full flex-col gap-10">
                    <h2 className="flex w-full items-center justify-between ">
                      <span>
                        {LectureInfoSuccess?.data?.session_history.hw_degree}
                      </span>
                      <span className="font-semibold">Degree</span>
                    </h2>
                    <h2 className="flex w-full items-center justify-between ">
                      <span>
                        {LectureInfoSuccess?.data?.session_history.averagehw}
                      </span>
                      <span className="font-semibold">Avarage Degrees</span>
                    </h2>
                    <h2 className="flex w-full items-center justify-between ">
                      <span>
                        {LectureInfoSuccess?.data?.session_history.rankhw}
                      </span>
                      <span className="font-semibold">
                        Student Rank in the lecture
                      </span>
                    </h2>
                  </div>
                )}
              </div>
            )}
            {/**
             * //!---- quiz -----
             */}
            {LectureInfoSuccess?.data?.session_history?.is_quiz === 1 && (
              <div className="quiz h-96 w-1/3 border-l-2 border-l-secondary  p-8 py-4 md:w-full md:border-r-0 md:border-t-2 md:border-t-secondary md:pt-10">
                <div className="mb-12 flex justify-between">
                  {LectureInfoSuccess?.data?.session_history?.is_quiz_result ===
                    1 &&
                  LectureInfoSuccess?.data?.session_history?.quiz_done === 1 ? (
                    <Link
                      to={`/home/lectures/sessions/modalAnswer/`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      Answer model
                    </Link>
                  ) : (
                    <Link
                      to={`/home/quiz/${lectureID}`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      Start the exam
                    </Link>
                  )}

                  <h2 className=" w-fit  text-3xl font-semibold	 underline decoration-wavy underline-offset-8 ">
                    <span className="mr-4">Exam</span>
                    <FontAwesomeIcon icon={faFileSignature} />
                  </h2>
                </div>

                {LectureInfoSuccess?.data?.session_history?.is_quiz_result ===
                  1 &&
                  LectureInfoSuccess?.data?.session_history?.quiz_done ===
                    1 && (
                    <div className="student-quiz-info flex w-full flex-col gap-10">
                      <h2 className="flex w-full items-center justify-between ">
                        <span>
                          {
                            LectureInfoSuccess?.data?.session_history
                              .quiz_degree
                          }
                        </span>
                        <span className="font-semibold">Degree</span>
                      </h2>
                      <h2 className="flex w-full items-center justify-between ">
                        <span>
                          {LectureInfoSuccess?.data?.session_history.averageqz}
                        </span>
                        <span className="font-semibold">Avarage Degrees</span>
                      </h2>
                      <h2 className="flex w-full items-center justify-between ">
                        <span>
                          {LectureInfoSuccess?.data?.session_history.rankqz}
                        </span>
                        <span className="font-semibold">
                          Student Rank in the lecture
                        </span>
                      </h2>
                    </div>
                  )}
              </div>
            )}
          </section>
        )}
      {/**
       * //!---- lecture-bottom -----
       */}
      <div className="lecture-bottom video-playlist  mt-24 flex w-full items-start justify-between gap-24 lg:gap-16 md:flex-col-reverse md:items-center">
        <div className="w-2/3 md:w-full">
          <div
            className={`
              ${
                LectureInfoSuccess?.data?.session_history?.views === 0
                  ? "hidden"
                  : "flex"
              } video  width justify-end`}
          >
            <div
              className={`iframe-wrapper aspect-video overflow-hidden rounded-2xl  ${
                state.currentVideo !== null && "border-secondary border-2 "
              } w-full`}
            >
              <VdoCiperrPlayer
                videoId={state.currentVideo}
                platform={state.platform}
                // views={state.views}
                history_id={LectureInfoSuccess?.data?.session_history?.key}
                videoRef={videoRef}
                videoContainerRef={videoContainerRef}
                isAPIReady={isAPIReady}
                setVideoRef={setVideoRef}
              />
              {state.platform !== "vdocipher" && state.platform !== "bunny" ? (
                <iframe
                  src={state.src}
                  width="100%"
                  height="100%"
                  title="YouTube video player"
                  //style={{ aspectRatio: "16 / 9" }}
                  allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  id="courseVideo"
                ></iframe>
              ) : null}

              {state.platform === "bunny" && (
                <BunnyVideo
                  src={state.src}
                  history_id={LectureInfoSuccess?.data?.session_history?.key}
                />
              )}
            </div>
          </div>
          {state.src && (
            <p className="font-bold text-3xl mt-12 text-center">
              {LectureInfoSuccess?.data?.session_history?.views === 0
                ? "You have exceeded the viewing limit"
                : `views: ${LectureInfoSuccess?.data?.session_history?.views}`}
            </p>
          )}
        </div>

        <div className="playlist w-1/3  md:w-full">
          <div
            className={`toggle-playlist w-full overflow-hidden rounded-2xl border-2  border-secondary  ${
              state.collapse ? "h-[64px] bg-secondary text-light " : "h-[465px]"
            } transition-{height} duration-500 ease-in-out `}
          >
            <div
              className="toggle-playlist  h-[64px] w-full cursor-pointer items-center justify-between gap-8 border-b-2 border-b-secondary  px-8 "
              onClick={() =>
                dispatch({
                  type: "setCollapse",
                  payload: !state.collapse,
                })
              }
            >
              <div dir="ltr" className="toggle-right font-semibold"></div>
              <div className="toggle-left flex items-center justify-between gap-5 h-full">
                <span className="text- font-semibold">Lecture content</span>
                <FontAwesomeIcon
                  className={`${
                    state.collapse ? "rotate-180" : ""
                  } text-3xl transition-transform duration-500 ease-in-out`}
                  icon={faChevronUp}
                />
              </div>
            </div>
            <div
              className={`playlist flex h-[400px] w-full flex-col gap-7 overflow-y-auto p-8 transition-opacity duration-500 ease-in-out ${
                state.collapse ? "opacity-0 " : "opacity-100"
              }`}
            >
              {LectureInfoSuccess?.data?.videos?.map((video, index) => {
                let src =
                  video.platform === "youtube"
                    ? `https://www.youtube.com/embed/${video.vdo_id}`
                    : video.platform === "bunny"
                      ? `https://vz-a4c75efd-fa3.b-cdn.net/${video.vdo_id}/playlist.m3u8`
                      : video.platform === "vimeo"
                        ? `https://player.vimeo.com/video/${video.vdo_id}`
                        : `https://player.vdocipher.com/v2/?otp=${video.OTP}&playbackInfo=${video.playbackInfo}`;

                return (
                  <div
                    className={`single-video w-full  ${
                      video.is_public === 1 ||
                      LectureInfoSuccess?.data?.session_info?.is_buy === 1
                        ? "cursor-pointer "
                        : " cursor-not-allowed"
                    }`}
                    key={video.key}
                  >
                    <div
                      onClick={() => {
                        dispatch({
                          type: "setCurrentVideo",
                          payload: index,
                        });
                        dispatch({
                          type: "setSrc",
                          payload: src,
                        });
                        dispatch({
                          type: "setPlatform",
                          payload: video.platform,
                        });

                        // vdociper logic
                        videoContainerRef.current.innerHTML = "";

                        videoContainerRef.current.classList.add("haveVideo");
                        const videoRef = loadVideo({
                          otp: video.OTP,
                          playbackInfo: video.playbackInfo,
                          container: videoContainerRef.current,
                          configuration: {
                            // autoplay: true
                          },
                        });
                        setVideoRef(videoRef);
                      }}
                      className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out ${
                        video.is_public === 1 ||
                        LectureInfoSuccess?.data?.session_info?.is_buy === 1
                          ? "hover:bg-secondary hover:text-white "
                          : " pointer-events-none"
                      }
                          ${
                            state.currentVideo === index &&
                            " bg-secondary text-white"
                          }`}
                    >
                      <div
                        dir="ltr"
                        className={`above flex w-full items-center justify-between gap-2`}
                      >
                        <div className="above-title w-session flex items-center justify-start gap-7">
                          <FontAwesomeIcon icon={faVideo} />
                          <p className="text- w-11/12 truncate hover:text-clip ">
                            {index + 1}. {video.name}
                          </p>
                        </div>
                        <div className="above-icon flex w-[20px] justify-end">
                          <FontAwesomeIcon
                            icon={
                              video.is_public === 1 ||
                              LectureInfoSuccess?.data?.session_info?.is_buy ===
                                1
                                ? faLockOpen
                                : faUnlockKeyhole
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/**
               * //!---- PDFs -----
               */}
              {LectureInfoSuccess?.data?.attachments?.map(
                (attachment, index) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href={
                        attachment.is_public === 1 ||
                        LectureInfoSuccess?.data?.session_info?.is_buy === 1
                          ? attachment.address
                          : null
                      }
                      download={attachment.name}
                      rel="noreferrer"
                      target={
                        attachment.is_public === 1 ||
                        LectureInfoSuccess?.data?.session_info?.is_buy === 1
                          ? "_blank"
                          : null
                      }
                      className={`single-attachment w-full  ${
                        attachment.is_public === 1 ||
                        LectureInfoSuccess?.data?.session_info?.is_buy === 1
                          ? "cursor-pointer "
                          : " cursor-not-allowed"
                      }`}
                      key={attachment.key}
                    >
                      <div
                        className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out ${
                          attachment.is_public === 1 ||
                          LectureInfoSuccess?.data?.session_info?.is_buy === 1
                            ? ""
                            : " pointer-events-none"
                        }
                          hover:bg-secondary hover:text-white`}
                      >
                        <div
                          dir="ltr"
                          className={`above flex w-full items-center justify-between gap-2`}
                        >
                          <div className="above-title w-session flex items-center justify-start gap-7">
                            <FontAwesomeIcon icon={faFileLines} />
                            <p className="text- w-11/12 truncate hover:text-clip ">
                              {index + 1}. {attachment.name}
                            </p>
                          </div>
                          <div className="above-icon flex w-[20px] justify-end">
                            <FontAwesomeIcon
                              icon={
                                attachment.is_public === 1 ||
                                LectureInfoSuccess?.data?.session_info
                                  ?.is_buy === 1
                                  ? faLockOpen
                                  : faUnlockKeyhole
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StudentLecture;
